export const works = [
  {
    company: 'Konrad Group',
    role: 'Software Developer',
    dateStart: 'Jul 2024',
    dateEnd: 'Present',
    totalTime: '',
    companyLogo: 'kg',
    companyUrl: 'https://www.konrad.com/',
    keyPoints: [
      'Implemented modular and interactive React/Typescript components integrated with Contentstack CMS, enhancing content management flexibility and user experience.',
      'Implemented API endpoints in MuleSoft Anypoint Studio using DataWeave, leveraging the Anypoint Platform cloud suite for scalable API management and Splunk for real-time monitoring.',
    ],
  },
  {
    company: 'Konrad Group',
    role: 'Associate Software Developer',
    dateStart: 'Jul 2023',
    dateEnd: 'Jul 2024',
    totalTime: '1 year 1 month',
    companyLogo: 'kg',
    companyUrl: 'https://www.konrad.com/',
    keyPoints: [
      'Implemented GraphQL API endpoints in Java Spring WebFlux, leveraging non-blocking reactive streams to provide high-throughput and efficient resource utilization.',
      'Fixed functionality and accessibility bugs in an existing React/Typescript project, enhancing user experience and compliance with accessibility standards.',
      'Implemented and integrated custom WordPress block components in React and PHP, engineered with hydration to enhance SEO performance.',
      'Reviewed code and provided technical guidance to interns, ensuring alignment with company code quality standards and best practices.',
    ],
  },
  {
    company: 'Konrad Group',
    role: 'Full Stack Developer Intern',
    dateStart: 'Feb 2023',
    dateEnd: 'Jun 2023',
    totalTime: '5 months',
    companyLogo: 'kg',
    companyUrl: 'https://www.konrad.com/',
    keyPoints: [
      'Implemented backend REST API endpoints in Java Spring Boot using layered architecture, ensuring maintainable and scalable code.',
      'Implemented A11Y compliant accessible UI components in React/Typescript within a modular architecture, enhancing user experience and code reusability.',
      'Implemented API JWT-based authentication and role-based authorization, leveraging Google Identity services for secure and seamless user management.',
      'Designed the project SQL database and created Java Flyway database migrations to ensure consistent and version-controlled database changes.',
    ],
  },
  {
    company: 'Tecnológico de Costa Rica',
    role: 'Web Developer',
    dateStart: 'Feb 2022',
    dateEnd: 'Nov 2022',
    totalTime: '10 months',
    companyLogo: 'tec',
    companyUrl: 'https://www.tec.ac.cr/',
    keyPoints: [
      'Implemented responsive frontend web components using HTML, CSS/SASS, and JavaScript/jQuery, from Figma designs.',
      'Integrated frontend web components in Drupal CMS using PHP Twig templates.',
    ],
    workUrl: 'https://codepen.io/marianosegura',
  },
];
